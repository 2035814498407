<ion-item-sliding #item>
  <ion-item
    [lines]="lines"
    [attr.detail]="showArrow ? true : null"
    [ngClass]="{ disabled: disabled }"
    color="{{ color }}"
    class="{{ isRoadmap ? 'reduced-padding' : null }}">
    <div [class.chevron-button-container]="showChevronButtons || isChild">
      <ion-buttons *ngIf="isRoadmap">
        <ion-button class="chevron-button" (click)="hasChildren ? toggleMultiPerimeterChildren($event) : null">
          <i class="fal fa-chevron-right" *ngIf="hasChildren && isCollapsed"></i>
          <i class="fal fa-chevron-down" *ngIf="hasChildren && !isCollapsed"></i>
          <i class="fal fa-baby children-icon" *ngIf="isChild"></i>
        </ion-button>
      </ion-buttons>
      <div *ngIf="chaptersGroup?.childrenCount > 0">({{ chaptersGroup.childrenCount }})</div>
    </div>
    <!-- Thumbnail picture -->
    <div class="picture" *ngIf="!!picture" (click)="pictureClicked.emit()">
      <app-picture [picture]="picture"></app-picture>
    </div>
    <ion-label class="{{ padding ? 'ion-padding-' + padding : null }}" (click)="onItemClicked()">
      <div
        class=" {{ isRefData ? 'text-style6' : 'text-style2' }}
        text-overflow-ellipsis {{ disabledLegend ? 'disabled-legend' : null }} {{ labelClass }}">
        {{ legend }}
        <ng-content select="[role=after-legend]"></ng-content>
      </div>
      <div class="content text-style1" [ngClass]="{ 'color-unknown': readOnly, 'text-style1-sm': isRoadmap }">
        <ng-content></ng-content>
      </div>
    </ion-label>
    <div item-end><ng-content select="[role=end]"></ng-content></div>
  </ion-item>
  <ion-item-options side="end" class="slide-buttons" *ngIf="itemOptions">
    <ion-item-option
      *ngIf="$any(itemOptions).addButton"
      color="{{ $any(itemOptions).addButton.color }}"
      (click)="itemAdd(item)">
      <i class="fal fa-plus {{ $any(itemOptions).addButton.color === 'mono-perimeter' ? 'color-white' : null }}"></i>
    </ion-item-option>
    <ion-item-option
      *ngIf="$any(itemOptions).unlink"
      color="{{ $any(itemOptions).unlink.color }}"
      (click)="unlink(item)">
      <i class="fal fa-unlink"></i>
    </ion-item-option>
    <ion-item-option
      *ngIf="$any(itemOptions).redirect"
      color="{{ $any(itemOptions).redirect.color }}"
      (click)="redirect(item)">
      <ion-icon src="assets/img/building-tag.svg"></ion-icon>
    </ion-item-option>
    <ion-item-option color="delete" (click)="itemDelete(item)">
      <i class="fal fa-trash-alt"></i>
    </ion-item-option>
  </ion-item-options>
  <ion-item-options *ngIf="clearButton">
    <ion-button color="danger" (click)="clearClicked(item, $event)">
      {{ "Clear" | translate }}
    </ion-button>
  </ion-item-options>
</ion-item-sliding>
